import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { sanityConfig } from "../sanityConfig"
import SEO from "../components/SEO"
import Hero, { HeroTitleDescription } from "../components/Hero"
import { convertToBgImage } from "gbimage-bridge"
import PageContentContainer from "../components/PageContentContainer"
import BannerContainer from "../components/bannerContainer"
import BannerTitleNew from "../components/BannerTitleNew"
import WaveDivider from "../components/svgs/WaveDivider"

const Gallery = ({ data }) => {
  const { allSanityAlbums, banner } = data

  const galleryHeroFluid = getGatsbyImageData(
    banner.contactBanner.asset.id,
    { maxWidth: 4100 },
    sanityConfig
  )
  const bgImage = convertToBgImage(galleryHeroFluid)

  return (
    <Layout className="mx-10 text-center ">
      <SEO title="Gallery" />

      {/* <Hero>
        <HeroTitleDescription
          title="Gallery"
          text="Lorem ipsum, or lipsum as it is sometimes known, is dummy text. Lorem ipsum, or lipsum as it is sometimes known, is dummy text. Lorem ipsum, or lipsum as it is  ometimes. "
        />
      </Hero> */}
      <PageContentContainer>
        <BannerContainer color="bg-secondary text-primary py-20">
          <BannerTitleNew
            props={{
              divider: <WaveDivider className=" fill-tertiary w-20 md:w-32" />,
              divStyle: "h-1/6 w-full place-items-center",
              doubleDivider: true,
              text: (
                <h1 className="text-5xl md:text-6xl  font-semibold text-primary">
                  Gallery
                </h1>
              ),
            }}
          />{" "}
          <section className="grid ">
            {/* List or grid of albums */}

            <div className="flex gap-x-10 gap-y-10 justify-center flex-wrap mt-10 ">
              {(allSanityAlbums.edges.length > 0 &&
                allSanityAlbums.edges.map(({ node: album }) => {
                  {
                    /* Use  albumCover if provided in CMS, or use first image in array as cover */
                  }

                  const altText =
                    album?.albumCover?.myImage?.asset?.alt ||
                    album?.albumImages[0]?.asset?.alt ||
                    "NO ALT PROVIDED"

                  const albumCoverAsset =
                    album.albumCover?.myImage?.asset?.gatsbyImageData ||
                    album.albumImages[0].asset.gatsbyImageData

                  return (
                    <Link
                      to={`/gallery/${album.slug.current}`}
                      className="grid"
                    >
                      <GatsbyImage
                        image={albumCoverAsset}
                        alt={altText}
                        className="shadow-card bg-white"
                      />
                      <h3 className="text-4xl mt-5 font-semibold text-center">
                        {album.title}
                      </h3>
                    </Link>
                  )
                })) || <div>No albums yet. Check back soon!</div>}
            </div>
          </section>
        </BannerContainer>
      </PageContentContainer>
    </Layout>
  )
}

// current queries for all of the images, in all of the albums. Not ideal!

export const query = graphql`
  query GalleryPageQuery {
    banner: sanityContactPage {
      contactBanner {
        asset {
          id
          url
        }
      }
    }

    allSanityAlbums {
      edges {
        node {
          title
          slug {
            current
          }
          albumImages {
            asset {
              altText
              gatsbyImageData(aspectRatio: 1, height: 300, placeholder: BLURRED)
            }
          }
          albumCover {
            myImage {
              asset {
                altText
                gatsbyImageData(
                  aspectRatio: 1
                  height: 300
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`

export default Gallery
